import { getAppVariable } from '@white-label-helper/get-app-variable';

/**
 * Using the Intl.NumberFormat() constructor to converts a number into a currency string based on the partner language setting.
 * @param price - The price to format
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */

export const formatPrice = (price: number): Intl.NumberFormat | string => {
  const defaultCurrency = getAppVariable('default_currency');
  const defaultLanguage = getAppVariable('default_language') || [];
  return Number.isNaN(price)
    ? ''
    : new Intl.NumberFormat(defaultLanguage, {
        style: "currency",
        currency: defaultCurrency,
        currencyDisplay: "narrowSymbol",
        maximumFractionDigits: 2,
      }).format(price);
};

/**
 * Returns an Intl.NumberFormat instance configured with the default currency.
 * This formatter can be used for parsing price components.
 * @returns An instance of Intl.NumberFormat.
 */
export const formatCurrency = (): Intl.NumberFormat => {
  const defaultCurrency = getAppVariable('default_currency');

  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: defaultCurrency,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
