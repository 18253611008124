import { render, staticRenderFns } from "./booking-item.vue?vue&type=template&id=ec208d02&scoped=true"
import script from "./booking-item.vue?vue&type=script&lang=ts"
export * from "./booking-item.vue?vue&type=script&lang=ts"
import style0 from "./booking-item.vue?vue&type=style&index=0&id=ec208d02&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec208d02",
  null
  
)

export default component.exports