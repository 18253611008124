
// Packages
import { PropType, defineComponent } from 'vue';

// Constants
import {
  HTTP_STATUS_CODES,
  BOOKING_STATUSES,
  NAMED_ROUTES,
  CookiesNames,
} from '@white-label-configuration/constants';

// Types
import type { CookieSerializeOptions } from 'cookie';
import type { Partners } from '@white-label-types/partners';
import { Booking, OrderItem } from '@white-label-types/account-booking';
type BookingStatues = keyof typeof BOOKING_STATUSES;

// Helpers
import {
  guessTimezone,
  DATE_TIME_FORMATS,
  parseISO,
  utcToZonedTime,
  format,
  isSameDay,
  isBefore,
  isValid,
} from '@white-label-helper/date-utilities';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { searchBooking } from '@white-label-helper/api-manage-booking';

// Components
import DotsLoader from '../dots-loader/dots-loader.vue';
import BookingStatus from '../booking-status/booking-status.vue';
import TextButton from '../text-button/text-button.vue';
import ButtonControl from '../button/button.vue';
import { capitalize, orderBy } from 'lodash';
import { TranslateResult } from 'vue-i18n';

export default defineComponent({
  name: 'BookingItem',

  components: {
    BookingStatus,
    DotsLoader,
    TextButton,
    ButtonControl,
  },

  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
    singleProduct: {
      type: Boolean,
    },
    customStatus: {
      type: String,
      default: '',
    },
    canEditOrder: {
      type: Boolean,
      default: true,
    },
    disableOrdersThatAreNotToday: {
      type: Boolean,
      default: false,
    },
    isLiveOrder: {
      type: Boolean,
      default: false
    },
    shouldShowCancelledItems: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loadingManageBooking: false,
    };
  },

  computed: {
    filteredOrderItems(): OrderItem[] {
      const bookingOrderItems = this.booking.orderItems || [];

      if(this.shouldShowCancelledItems) return bookingOrderItems

      return bookingOrderItems.filter(
        (orderItem) => {
          return orderItem?.status.toUpperCase() !== BOOKING_STATUSES.CANCELLED;
        }
      );
    },
    showOrderStatusPill(): boolean {
      if(this.isLiveOrder && this.questionnaireStatus === BOOKING_STATUSES.ACTION_REQUIRED) return false;
      return this.isPrivateTerminalBooking;
    },
    bookingStatus(): string {
      if(this.isLiveOrder) return '';
      return this.customStatus || this.booking.status.toUpperCase();
    },
    questionnaireStatus(): BookingStatues | null {
      const showQuestionnaireStatusTypes = this.canEditOrder
        ? ['new', 'draft']
        : [];
      return (this.booking.orderItems || []).some((item) =>
        showQuestionnaireStatusTypes.includes(item?.piiQuestionsStatus)
      )
        ? BOOKING_STATUSES.ACTION_REQUIRED
        : null;
    },
    displayBookingStatus(): boolean {
      if (this.isPrivateTerminalBooking) {
        return (
          [BOOKING_STATUSES.PAST_BOOKING, BOOKING_STATUSES.CANCELLED].includes(
            this.bookingStatus
          ) || this.customStatus
        );
      }
      return (
        this.bookingStatus !== BOOKING_STATUSES.CONFIRMED ||
        (this.customStatus && this.customStatus.length > 0)
      );
    },
    formattedDatesMobile(): string {
      return this.getFormattedDates(DATE_TIME_FORMATS.month_day_year);
    },

    formattedDatesDesktop(): string {
      const dateFormat =
        this.bookingStatus === BOOKING_STATUSES.PAST_BOOKING ||
        this.bookingStatus === BOOKING_STATUSES.CANCELLED
          ? DATE_TIME_FORMATS.month_day_year
          : DATE_TIME_FORMATS.full_month_day_year;

      return this.getFormattedDates(dateFormat);
    },

    displaySmallStatus(): boolean {
      return (
        this.displayBookingStatus &&
        this.bookingStatus !== BOOKING_STATUSES.IN_PROGRESS
      );
    },

    partnerTimeZone(): Partners['poi']['timezone'] {
      return getAppVariable('poi.timezone') || guessTimezone();
    },

    isPrivateTerminalBooking(): boolean {
      return this.booking.privateTerminalBooking;
    },
    hasLiveOrderItem() {
      for (const orderItem of this.booking.orderItems) {
        if (!this.isOrderInThePast(orderItem)) {
          return true;
        }
      }

      return false;
    },
  },

  methods: {
    /**
     * Checks if the booking is a parking booking and returns the formatted start & end date,
     * otherwise returns just the formatted start date.
     * @param {string} dateFormat - Date format
     * @returns {string} - Formatted dates
     */
    getFormattedDates(dateFormat: string): string {
      const entryDate = parseISO(
        `${this.booking.startDatetime.date} ${this.booking.startDatetime.time}`
      );

      const entryDateFormatted = isValid(entryDate)
        ? format(entryDate, dateFormat)
        : '';

      let exitDate,
        exitDateFormatted = '';
      if (this.booking.product_code === 'parking') {
        exitDate = parseISO(
          `${this.booking.endDatetime.date} ${this.booking.endDatetime.time}`
        );
        exitDateFormatted = isValid(exitDate)
          ? format(exitDate, dateFormat)
          : '';
      }

      return exitDateFormatted.length > 1
        ? `${entryDateFormatted} - ${exitDateFormatted}`
        : entryDateFormatted;
    },

    orderItemIsCancelled(orderItemStatus: OrderItem['status']): boolean {
      return orderItemStatus === BOOKING_STATUSES.CANCELLED;
    },


    showBookingItemStatusPill(bookingStatus: BookingStatues): boolean {
      if(bookingStatus === BOOKING_STATUSES.PAST_BOOKING && this.isLiveOrder) return false;
      return this.displayBookingStatus && this.isPrivateTerminalBooking
    },

    showRequestError() {
      this.$openModal('GlobalModalError', {
        header: this.$t('shared.modals.errors.header'),
        body: this.$t('shared.modals.errors.requestFailed.body'),
        btnText: this.$t('shared.buttons.close'),
        btnType: 'custom',
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    },

    async goToBooking() {
      try {
        this.loadingManageBooking = true;
        const { token, expiration } = await searchBooking({
          email: this.$auth.user?.email as string,
          reference_id: this.booking.id,
        });
        const options = {
          maxAge: expiration - new Date().getTime() / 1e3,
          path: '/',
          sameSite: true,
        } as CookieSerializeOptions;

        if (process.env.NODE_ENV === 'production') {
          options.domain = process.env.NUXT_ENV_DOMAIN;
        }

        this.$cookies.set(
          CookiesNames.manage,
          { token, timestamp: new Date() },
          options
        );
        await this.$router.push(NAMED_ROUTES.manage_booking.default);
      } catch (error: any) {
        const errorResponse = JSON.parse(error.message);

        if (errorResponse?.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
          this.$emit('update:isTouched', true);
          this.$emit('update:isInvalid', true);
        } else {
          this.showRequestError();
        }
      } finally {
        this.loadingManageBooking = false;
      }
    },

    async goToGuestsDetails(orderId: number) {
      try {
        this.loadingManageBooking = true;
        const { token, expiration } = await searchBooking({
          email: this.$auth.user?.email as string,
          reference_id: this.booking.id,
        });
        const options = {
          maxAge: expiration - new Date().getTime() / 1e3,
          path: '/',
          sameSite: true,
        } as CookieSerializeOptions;

        if (process.env.NODE_ENV === 'production') {
          options.domain = process.env.NUXT_ENV_DOMAIN;
        }

        this.$cookies.set(
          CookiesNames.manage,
          {
            token,
            timestamp: new Date(),
            id: this.singleProduct ? this.booking?.productId : '',
          },
          options
        );
        const route: string =
          (this.booking.orderItems || []).length > 1 && !this.singleProduct
            ? NAMED_ROUTES.booking_products.home
            : NAMED_ROUTES.booking_guest_details.home;
        this.$cookies.set('guestPiiData', {
          orderId: this.booking.productId ?? this.booking.orderItems[0].id,
        });
        await this.$router.push(route);
      } catch (error: any) {
        const errorResponse = JSON.parse(error.message);

        if (errorResponse?.status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
          this.$emit('update:isTouched', true);
          this.$emit('update:isInvalid', true);
        } else {
          this.showRequestError();
        }
      } finally {
        this.loadingManageBooking = false;
      }
    },

    getGuestsInfo(guests: OrderItem['groups']): string {
      const guestsInfo = [];
      for (const [group, value] of Object.entries(guests)) {
        if (value) {
          guestsInfo.push(`${value} x ${capitalize(group)}`);
        }
      }
      return guestsInfo.join(', ');
    },

    getFlightTime(flightDateTime: string): TranslateResult {
      const flightTime = format(
        new Date(flightDateTime),
        DATE_TIME_FORMATS.day_month_year_day_time_am_pm_lower_case
      );

      return this.$t('UI.productSummaryCard.info.flightTime', { flightTime });
    },

    getOrderItemFeatures(orderItem: OrderItem): string {
      const baggageType = this.$t(
        `bookings.orderItem.features.baggage.${orderItem.baggageType}`
      );
      const mealType = this.$t(
        `bookings.orderItem.features.meal.${orderItem.mealType}`
      );
      return `${orderItem.airlineName}, ${baggageType}, ${mealType}`;
    },

    isOrderItemInbound(direction: string): boolean {
      return direction === 'inbound';
    },
    isOrderInThePast(orderItem: OrderItem): boolean {
      const currentTime: Date = utcToZonedTime(
        new Date(),
        getAppVariable('poi.timezone') || guessTimezone()
      );
      const orderDate =
        orderItem.closedAt?.datetime || orderItem.startedAt?.datetime;
      return orderDate && !isSameDay(parseISO(orderDate), currentTime)
        ? isBefore(parseISO(orderDate), currentTime)
        : false;
    },
    disableOrder(orderItem: OrderItem): boolean {
      const currentTime: Date = utcToZonedTime(
        new Date(),
        getAppVariable('poi.timezone') || guessTimezone()
      );
      const orderDate =
        orderItem.closedAt?.datetime || orderItem.startedAt?.datetime;

      return (
        (!isSameDay(currentTime, parseISO(orderDate)) &&
        this.disableOrdersThatAreNotToday) || this.shouldShowCancelledItems
      );
    },
  },
});
