// Packages
import { mapActions } from 'pinia';
import { datadogRum } from '@datadog/browser-rum';

// Store
import { useDiscountCodeStore } from '@white-label-store/discount-code';
import { usePaymentFormStore } from '@white-label-store/payment-form';
import { usePreloaderControllerStore } from '@white-label-store/preloader-controller';
import { useMarketingPreferencesStore } from '@white-label-store/marketing-preferences';

// Constants
import {
  HTTP_ERROR_TYPES,
  NAMED_ROUTES,
  PAYMENT_METHOD_STRATEGIES,
  PAYMENT_PROVIDERS,
} from '@white-label-configuration/constants';

// Helpers
import {
  ACCOUNT_URLS,
  MANAGE_BOOKING_URLS,
  DOMAINS_NAMES,
  goToApp,
  getDomainUrl,
} from '@white-label-helper/switch-app';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { getAppPaymentProvider } from '@white-label-helper/get-app-payment-provider';
import { formatPrice } from '@white-label-helper/helper-payment';
import { getClientConfig } from '@white-label-helper/api-parkings-payment-config';
import { revalidateCartHelper } from '@white-label-helper/pusher-revalidate-cart';
import { isBookingPortal } from '@white-label-helper/is-booking-portal';
import { pushIdentityCallback } from '@white-label-helper/ga-tracking';

// Types
import type { CookieSerializeOptions } from 'cookie';
import type { CartRevalidationData } from '@white-label-types/pusher';
import type { TaxFee, CancellationPolicy, AmendmentPolicy } from '@white-label-types/parking-booking';
import type { QueryParams } from '@white-label-types/component';
import type { CheckoutMixin } from './checkout';
import { AdditionalFields, CancellationProtection } from '@white-label-types/parking-checkout';

const { revalidateCart, destroyChannelListener } = revalidateCartHelper();

const MAX_TRY_AGAIN_COUNT = 3;
const MAX_REVALIDATE_COUNT = 2;
const VALIDATE_WATCHER_TICK_TIME = 1000;

const checkout: CheckoutMixin = {
  revalidateCart,
  destroyChannelListener,

  interval: null,

  data() {
    return {
      checkout_cartItems: [],
      checkout_total: null,
      checkout_bookingFee: null,
      checkout_originalAmounts: null,
      checkout_paymentsConfig: {},
      checkout_paymentsConfigReceived: false,
      checkout_revalidateCount: 0,
      checkout_tryAgainCount: 0,
      checkout_forwardInfo: null,
      checkout_paymentIntentUpdated: false,
      checkout_amountToRefund: null,
    };
  },

  async mounted() {
    // TODO Need to delete when dev will moved to hosting, which supports Nuxt middleware
    this.browserSupportCheck();

    const revalidateCount = sessionStorage.getItem(
      this.checkout_revalidateCountLocalStorageKey
    );
    if (typeof revalidateCount === 'string') {
      // eslint-disable-next-line no-bitwise
      this.checkout_revalidateCountStored = ~~revalidateCount;
    }
    pushIdentityCallback(this.$gtm);
    await this.dispatchMarketingPreferences();
    await this.checkout_checkQuery();
  },

  beforeDestroy() {
    this.$options.destroyChannelListener();
    this.$options.destroyHandlePayment();
    if (this.$options.interval !== null) {
      clearInterval(this.$options.interval);
    }
  },

  computed: {
    /**
     * Returns the default currency from the generated partner config.
     * @returns a currency code string.
     */
    checkout_currency() {
      return getAppVariable('default_currency');
    },

    /**
     * Returns the cancellation policies of the current cart item.
     * @returns an array of cancellation policy objects.
     */
    checkout_cancellationPolicies() {
      return this.checkout_cartItem?.inventory_option?.cancellation_policies || []
    },

    /**
     * Returns the amendment policies of the current cart item.
     * @returns an array of amendment policy objects.
     */
    checkout_amendmentPolicies() {
      return this.checkout_cartItem?.inventory_option?.amendment_policies || [];
    },

    /**
     * Returns a string containing the current cart token, to be used as the
     * key when storing the revalidation count in session storage.
     * @returns a key for accessing local storage.
     */
    checkout_revalidateCountLocalStorageKey() {
      return `revalidateCount.${this.checkout_cartToken}`;
    },

    /**
     * Gets and sets the revalidate count from session storage.
     */
    checkout_revalidateCountStored: {
      get() {
        return this.checkout_revalidateCount;
      },
      set(val) {
        this.checkout_revalidateCount = val;
        sessionStorage.setItem(
          this.checkout_revalidateCountLocalStorageKey,
          val.toString()
        );
      },
    },

    /**
     * Returns the `token` from the current route query.
     * @returns a string cart token.
     */
    checkout_cartToken() {
      return typeof this.$route.query['token'] === 'string'
        ? this.$route.query['token']
        : '';
    },

    /**
     * Returns the first cart item in the current cart items.
     * @returns a cart item object.
     */
    checkout_cartItem() {
      return this.checkout_cartItems[0];
    },

    /**
     * Returns the inventory item name of the current cart item.
     * @returns a string name.
     */
    checkout_orderName() {
      return this.checkout_cartItem?.inventory_item.name;
    },

    /**
     * Returns the order total for this order.
     * @returns a number total.
     */
    checkout_orderTotal(): number {
      return this.checkout_total?.total || 0;
    },

    /**
     * Formats the order total into a string with currency symbol.
     * @returns a formatted string amount.
     */
    checkout_orderTotalFormatted() {
      return formatPrice(this.checkout_orderTotal);
    },

    /**
     * Formats the order subtotal into a string with currency symbol.
     * @returns a formatted string amount.
     */
    checkout_itemSubtotalFormatted() {
      return formatPrice(this.checkout_cartItem?.totals?.subtotal);
    },

    /**
     * Returns the product code for this cart item.
     * @returns A string product code.
     */
    productCode() {
      return this?.checkout_cartItem?.product_code || '';
    },

    /**
     * Formats the cart item total into a string with currency symbol.
     * @returns a formatted string amount.
     */
    checkout_itemTotalFormatted() {
      return formatPrice(this.checkout_cartItem?.totals?.total);
    },

    /**
     * Returns true if the cart item is a parking product.
     * @returns a boolean.
     */
    isParkingProduct(): boolean {
      return this?.checkout_cartItem?.product_code === 'parking';
    },

    /**
     * Returns true if the cart item is a lounge product.
     * @returns a boolean.
     */
    isLoungesProduct(): boolean {
      return this?.checkout_cartItem?.product_code === 'lounges';
    },

    /**
     * Concatenates the entry date and entry time into a string.
     * @returns a string datetime.
     */
    checkout_entryDateTime(): string {
      if (this.checkout_cartItem?.search_criteria.parking) {
        return `${this.checkout_cartItem?.search_criteria.parking.date1} ${this.checkout_cartItem?.search_criteria.parking.time1}`;
      }
      if (this?.checkout_cartItem?.search_criteria?.lounges) {
        return `${this?.checkout_cartItem?.search_criteria?.lounges.date} ${this.checkout_cartItem?.search_criteria?.lounges?.time}`;
      }
      return '';
    },

    /**
     * Concatenates the exit date and exit time into a string.
     * @returns a string datetime.
     */
    checkout_exitDateTime(): string {
      if (this.checkout_cartItem?.search_criteria.parking) {
        return `${this.checkout_cartItem?.search_criteria.parking.date2} ${this.checkout_cartItem?.search_criteria.parking.time2}`;
      }
      return '';
    },

    /**
     * Returns true if the user has clicked 'try again' more times
     * than allowed by the `MAX_TRY_AGAIN_COUNT`
     * @returns a boolean
     */
    checkout_isMoreThanMaxAgainCount() {
      return this.checkout_tryAgainCount < MAX_TRY_AGAIN_COUNT;
    },

    /**
     * Returns all taxes on this cart item.
     * @returns an array of Tax objects
     */
    checkout_taxes() {
      const taxes = this.checkout_cartItem?.taxes || [];
      return taxes.map(({ name, amount }) => ({
        name,
        amount: formatPrice(amount),
      }));
    },

    /**
     * Returns all fees on this cart item.
     * @returns an array of Fee objects
     */
    checkout_fees() {
      const fees = this.checkout_cartItem?.fees || [];
      return fees.map(({ name, amount }) => ({
        name,
        amount: formatPrice(amount),
      }));
    },

    /**
     * Returns a summary of all extras added to this cart item,
     * which currently only includes cancellation protection.
     * @returns A summary of cancellation protection info, or an empty object.
     */
    checkout_summaryExtras() {
      let cancellationProtectionTaxes = [] as TaxFee[];

      if (
        typeof this.checkout_cartItem?.cancellation_protection?.taxes !==
        'undefined'
      ) {
        cancellationProtectionTaxes =
          this.checkout_cartItem.cancellation_protection.taxes;
      }

      return this.checkout_cartItem?.is_protected
        ? {
          id: 'canc_prot',
          name: this.$t('payment.cancellationProtection'),
          totals: {
            total:
              this.checkout_cartItem.cancellation_protection?.totals?.total ??
              0,
            total_formatted: formatPrice(
              this.checkout_cartItem.cancellation_protection?.totals?.total ??
              0
            ),
            total_no_taxes:
              this.checkout_cartItem.cancellation_protection?.totals
                ?.subtotal ?? 0,
            total_no_taxes_formatted: formatPrice(
              this.checkout_cartItem.cancellation_protection?.totals
                ?.subtotal ?? 0
            ),
          },
          items: cancellationProtectionTaxes.map((item) => ({
            name: item.name,
            amount: item.amount,
            amount_formatted: formatPrice(item.amount),
          })),
        }
        : {};
    },

    /**
     * Parses the additional fields from the selected inventory item.
     * @returns additional fields from the selected inventory item, or an empty object.
     */
    checkout_additionalFields() {
      if (
        typeof this.checkout_cartItem?.inventory_item.additional_fields ===
        'object' &&
        !Array.isArray(this.checkout_cartItem?.inventory_item.additional_fields)
      ) {
        return this.checkout_cartItem.inventory_item.additional_fields;
      }
      return {};
    },

    /**
     * Parses the additional fields from all cart items.
     * @returns aggregated additional fields from all cart items, or an empty object.
     */
    checkout_multiCartAdditionalFields() {
      const additionalFields: AdditionalFields = {};
      for (const cartItem of this.checkout_cartItems) {
        if (
          typeof cartItem.inventory_item.additional_fields === 'object' &&
          !Array.isArray(cartItem.inventory_item.additional_fields)
        ) {
          if (!Object.keys(additionalFields).length) {
            Object.assign(
              additionalFields,
              JSON.parse(
                JSON.stringify(cartItem.inventory_item.additional_fields)
              )
            );
            continue;
          }

          for (const field in cartItem.inventory_item.additional_fields) {
            if (additionalFields[field] !== undefined) {
              additionalFields[field].is_show =
                cartItem.inventory_item.additional_fields[field]?.is_show ||
                additionalFields[field].is_show;
              additionalFields[field].is_required =
                cartItem.inventory_item.additional_fields[field]?.is_required ||
                additionalFields[field].is_required;
            }
          }
        }
      }
      return additionalFields;
    },

    /**
     * Returns the upgraded cancellation policies the first cart item.
     * @returns a cancellation policy object.
     */
    checkout_cancellationProtection(): CancellationProtection {
      return {
        ...this.checkout_cartItem?.cancellation_protection,
        product_name: this.checkout_cartItem?.inventory_item.name,
        product_id: this.checkout_cartItem?.id,
      };
    },

    /**
     * Returns the upgraded cancellation policies of all cart items.
     * @returns an array of cancellation policy objects.
     */
    checkout_multiCartCancellationProtection(): CancellationProtection[] {
      return this.checkout_cartItems.map((cartItem) => {
        return {
          ...cartItem.cancellation_protection,
          product_name: cartItem.inventory_item.name,
          product_id: cartItem.id,
          is_bundle_product: !!cartItem.bundle_id,
        };
      });
    },

    /**
     * Returns the cancellation policies of all cart items.
     * @returns an array of CancellationPolicy objects.
     */
    checkout_multiCartCancellationPolicies(): CancellationPolicy[] {
      return this.checkout_cartItems.map((cartItem) => {
        return {
          cancellation_policy: cartItem.inventory_option.cancellation_policies[0].message,
          product_name: cartItem.inventory_item.name,
          product_id: cartItem.id,
          is_bundle_product: !!cartItem.bundle_id,
        };
      });
    },

    /**
     * Returns the amendment policies of all cart items.
     * @returns an array of AmendmentPolicy objects.
     */
    checkout_multiCartAmendmentPolicies(): AmendmentPolicy[] {
      return this.checkout_cartItems.map((cartItem) => {
        return {
          amendment_policy: cartItem.inventory_option.amendment_policies[0].message,
          product_name: cartItem.inventory_item.name,
          product_id: cartItem.id,
          is_bundle_product: !!cartItem.bundle_id,
        };
      });
    },


    /**
     * Checks if the windcave is the payment provider and if feature flag is enabled
     */
    checkout_isWindcavePayment(): boolean {
      const isFeatureFlagEnabled = this.$launchDarkly.variation(
        'Enable_Windcave_Setup'
      );
      const isWindcavePaymentChosen = getAppPaymentProvider() === PAYMENT_PROVIDERS.WINDCAVE;
      return isFeatureFlagEnabled && isWindcavePaymentChosen;
    },

    /**
     * Determines if the transaction is considered a refund.
     * @return {boolean} True if `checkout_amountToRefund` is a number and is less than or equals to zero.
     */
    checkout_isRefund(): boolean {
      return typeof this.checkout_amountToRefund === 'number' && this.checkout_amountToRefund <= 0;
    }
  },

  methods: {
    ...mapActions(usePreloaderControllerStore, {
      commitUpdatePreloader: 'updatePreloader',
    }),

    ...mapActions(useMarketingPreferencesStore, {
      dispatchMarketingPreferences: 'dispatchMarketingPreferences',
    }),

    ...mapActions(usePaymentFormStore, {
      commitClearFormData: 'commitClearFormData',
    }),

    ...mapActions(useDiscountCodeStore, {
      clearDiscountCode: 'clearDiscountCode',
    }),

    goToSecondStep() {
      return goToApp(
        getDomainUrl(DOMAINS_NAMES.ECOMMERCE, this.$launchDarkly),
        MANAGE_BOOKING_URLS.BOOKING_SEARCH,
      );
    },

    /**
     * Generates options for storing a cookie.
     * @param expiration the max age in ms for a cookie
     * @returns the cookie options
     */
    checkout_generateCookiesOptions(expiration) {
      const options = {
        path: '/',
      } as CookieSerializeOptions;

      // Setting cookies lifetime duration
      if (expiration) {
        options.maxAge = this.checkout_getTimeDuration(expiration);
      }

      if (process.env.NODE_ENV !== 'production') {
        // if process run on 'localhost' we need to share token in sameSite
        options.sameSite = true;
      } else {
        // if process run on 'AWS' we need to share token in domain
        options.domain = process.env.NUXT_ENV_DOMAIN;
      }

      return options;
    },

    /**
     * Generates options for and sets the management token cookie,
     * then navigates to a receipt page.
     * @param options token and expiration
     * @param routerKey constant determining which receipt page to visit
     */
    checkout_setCookiesAndGoToReceipt({ token, expiration }, routerKey) {
      this.$cookies.set(
        'managementToken',
        token,
        this.checkout_generateCookiesOptions(expiration)
      );

      switch (routerKey) {
        case 'BOOKING_RECEIPT':
          goToApp(
            getDomainUrl(DOMAINS_NAMES.ECOMMERCE),
            MANAGE_BOOKING_URLS.BOOKING_RECEIPT
          );
          break;
        case 'MODIFY_RECEIPT':
          goToApp(
            getDomainUrl(DOMAINS_NAMES.ECOMMERCE, this.$launchDarkly),
            MANAGE_BOOKING_URLS.BOOKING_RECEIPT,
            `modify-receipt=true`
          );
          break;
        default:
          break;
      }
    },

    /**
     * If booking was already cancelled, redirects to my account.
     * If product was deleted or has no price, opens expired modal.
     * If windcave is the payment provider - handle windcave error
     * Otherwise, show generic technical error message and disable preloaders.
     * @param error the error event.
     */
    async checkout_handlePaymentError({ errorType, error, type }) {
      if (
        errorType === 'OrderCreatingFailedEvent' ||
        errorType === 'OrderUpdatingFailedEvent'
      ) {
        const errorTypeText = this.checkout_getError(error);
        // TODO in the future it will be great if we can create an 'errorCodes' document,
        // which will describe what each error means and in which cases they will be shown.
        if (type === HTTP_ERROR_TYPES.BOOKING_ALREADY_CANCELLED) {
          this.$cookies.set(
            'crossAppError',
            'bookingModifyError',
            this.checkout_generateCookiesOptions()
          );
          goToApp(
            getDomainUrl(DOMAINS_NAMES.ACCOUNT),
            ACCOUNT_URLS.MANAGE_BOOKING
          );
        } else if (
          ['product_deleted', 'product_no_price', 'capacity_validation_errors'].includes(errorTypeText)
        ) {
          this.checkout_openProductNotAvailableModal();
        } else if (this.checkout_isWindcavePayment) {
          await this.checkout_handleWindcaveReload(true);
        } else {
          this.checkout_showBookingTechError();
        }
      } else {
        datadogRum.addError(error);
        console.log('checkout_handlePaymentError', error); // This is a temporary console.log in order to debug https://magusa.atlassian.net/browse/EM-169
        this.checkout_showTechnicalIssueError();


        if (this.checkout_isWindcavePayment) {
          await this.checkout_handleWindcaveReload(true);
        }
      }

      this.commitUpdatePreloader('');
    },


    /**
     * Handles the reload process for the Windcave payment integration.
     * First, it has to get a new client config
     * Then, it triggers UI updates with reloading the Windcave widget.
     * @param showError
     */
    async checkout_handleWindcaveReload(showError = false) {
      try {
        // The /client-config request should not be triggered if the order total is less than 0.
        // Ensure the submit button is not disabled in this case.
        if (this.checkout_orderTotal > 0) {
          await this.checkout_checkQuery();
        } else {
          this.$refs?.paymentForm?.setSubmitButtonDisableState(false);
        }
      } catch(error) {
        datadogRum.addError(error);
      } finally {
        this.$global_eventEmitter.$emit('windcaveHandleReload', showError);
      }
    },

    /**
     * Shows error modal with booking already cancelled message.
     */
    checkout_showBookingAlreadyCancelledModal() {
      this.$openModal('GlobalModalError', {
        header: this.$t('UI.modalError.bookingAlreadyCancelled.header'),
        body: this.$t('UI.modalError.bookingAlreadyCancelled.body'),
        btnText: this.$t('UI.button.close'),
        btnType: 'custom',
      })
        .then(this.onGoBack)
        .catch(() => { });
    },

    /**
     * Recursively validates cart, opening the session expired modal
     * if the cart is no longer valid.
     */
    checkout_revalidateCartRecursive(is_valid, isInitial, valid_till) {
      if (this.checkout_revalidateCount >= MAX_REVALIDATE_COUNT) {
        if (isInitial) {
          this.checkout_openExpiredModal();
        } else {
          this.checkout_validWatcher(valid_till).then(
            this.checkout_openExpiredModal
          );
        }
      } else if (is_valid) {
        this.checkout_validWatcher(valid_till).then(
          this.checkout_revalidateCart
        );
      } else {
        this.checkout_revalidateCart();
      }
    },

    /**
     * Validates the cart, opening the session expired modal
     * if the cart is no longer valid.
     */
    checkout_revalidateCart() {
      this.$options
        .revalidateCart(this.checkout_cartToken)
        .then(({ valid_till, available_results }: CartRevalidationData) => {
          this.checkout_revalidateCountStored += 1;
          if (available_results.includes(this.checkout_cartItem.id)) {
            this.checkout_revalidateCartRecursive(true, false, valid_till || 0);
          } else {
            this.checkout_openExpiredModal();
          }
        })
        .catch(this.checkout_openExpiredModal);
    },

    /**
     * Check for expired session at an interval of `VALIDATE_WATCHER_TICK_TIME`,
     * clear interval and resolve when session has expired.
     */
    checkout_validWatcher(valid_till) {
      return new Promise<void>((resolve) => {
        this.$options.interval = window.setInterval(() => {
          const isExpired = this.checkout_getTimeDuration(valid_till) <= 0;
          if (isExpired && this.$options.interval !== null) {
            clearInterval(this.$options.interval);
            resolve();
          }
        }, VALIDATE_WATCHER_TICK_TIME);
      });
    },

    /**
     * Checks for a cart token in the router query, and fetches the cart when found.
     * If not found, redirect to the 404 page.
     */
    async checkout_checkQuery() {
      if (!this.checkout_cartToken) {
        await this.$router.push(NAMED_ROUTES.not_found);
        return;
      }


      await this.getCart();
      if (!isBookingPortal && !this.checkout_isRefund) {
        await this.checkout_handlePaymentConfig();
      }
    },


    /**
     * Handles payment config depend on PSP (Windcave PSP needs additional data)
     * Indicates when the client config is received to provide appropriate error handling
     */
    async checkout_handlePaymentConfig() {
      // If the checkout is modified, apply the difference amount from /modify-checkout;
      // Otherwise, apply the total amount.
      const windcaveConfig = this.checkout_isWindcavePayment
      ? {
          amount: this.checkout_amountToRefund ?? this.checkout_orderTotal,
          currency: this.checkout_currency,
        }
      : null;


      try {
        this.checkout_paymentsConfig = await getClientConfig(windcaveConfig);
      } catch(error) {
        datadogRum.addError(error);
      } finally {
        this.checkout_paymentsConfigReceived = true;
      }
    },

    /**
     * Parses the type from an error event, or returns the entire error
     * if unable to parse.
     * @param error error event
     * @returns error type if present, or entire error object otherwise
     */
    checkout_getError(error) {
      try {
        return JSON.parse(error).type;
      } catch {
        return error;
      }
    },

    /**
     * Calculates duration between a given epoch time and the current epoch time.
     */
    checkout_getTimeDuration(backendTimestamp) {
      return backendTimestamp - new Date().getTime() / 1000;
    },

    /**
     * Opens the session expired modal.
     */
    checkout_openExpiredModal() {
      this.$openModal('GlobalModalError', {
        header: this.$t(`UI.modalError.bookingSessionExpiredError`),
        body: this.$t('UI.modalError.pleaseSearchAgain'),
        btnText: this.$t('UI.button.searchAgain'),
      })
        .then(this.goToSecondStep)
        .catch(() => { });
    },

    checkout_openProductNotAvailableModal() {
      this.$openModal('GlobalModalError', {
        header: this.$t(`UI.modalError.bookingProductNotAvailableErrorHeader`),
        body: this.$t('UI.modalError.bookingTechError'),
        btnText: this.$t('UI.button.searchAgain'),
      })
        .then(this.goToSecondStep)
        .catch(() => { });
    },

    /**
     * Opens the booking technical error modal.
     */
    checkout_showBookingTechError() {
      this.$openModal('GlobalModalError', {
        header: this.$t(`UI.modalError.${this.productCode}NoLongerAvailable`),
        body: this.$t('UI.modalError.bookingTechError'),
        btnText: this.$t('UI.button.tryAgain'),
      })
        .then(() => {
          this.$router.go(0);
        })
        .catch(() => { });
    },

    /**
     * Opens the generic technical issue error modal.
     */
    checkout_showTechnicalIssueError() {
      const body = this.checkout_isMoreThanMaxAgainCount
        ? this.$t('UI.modalError.technicalIssueError')
        : this.$t('UI.modalError.technicalIssueGoStepBack');
      const btnText = this.checkout_isMoreThanMaxAgainCount
        ? this.$t('UI.button.tryAgain')
        : this.$t('UI.button.chooseAProduct');
      const prependIcon = this.checkout_isMoreThanMaxAgainCount
        ? ''
        : 'IconArrow';
      this.$openModal('GlobalModalError', {
        header: this.$t('UI.modalError.header'),
        body,
        btnText,
        class: 'global-modal__checkout',
        prependIcon,
      })
        .then(this.checkout_retryGetReceipt)
        .catch(() => { });
    },

    /**
     * Retry `getCart` request and increment try again count.
     * Go back a step if max tries has been exceeded.
     */
    checkout_retryGetReceipt() {
      if (this.checkout_isMoreThanMaxAgainCount) {
        this.checkout_tryAgainCount += 1;
        this.getCart();
      } else {
        this.onGoBack();
      }
    },

    /**
     * Generates a query string from the search criteria for this cart item,
     * which can be used to re-create the same search when going back to the previous
     * step.
     * @returns a query param object.
     */
    checkout_getQueryForGoBack() {
      let queryParams = {} as QueryParams;
      if (this.checkout_cartItem?.search_criteria?.parking) {
        const searchCriteria = this.checkout_cartItem.search_criteria.parking;
        queryParams = {
          entryDate: searchCriteria.date1,
          entryTime: searchCriteria.time1,
          exitDate: searchCriteria.date2,
          exitTime: searchCriteria.time2,
          terminal: searchCriteria.terminal || 'dontKnow'
        };

        if (searchCriteria.coupon_code) {
          queryParams.discount = searchCriteria.coupon_code;
        }

        if (searchCriteria.airport_code) {
          queryParams.airport = searchCriteria.airport_code;
        }
      }
      if (this.checkout_cartItem.meta?.original_order_item_id) {
        queryParams.amendItemId = this.checkout_cartItem.meta.original_order_item_id.toString();
      }
      return queryParams;
    },

    /**
     * Clear discount code and form data from store.
     */
    checkout_onSuccessCleaningStorage() {
      this.clearDiscountCode();
      this.commitClearFormData();
    },

    /**
     * Push google tag manager event.
     */
    checkout_trackOnBookingComplete(actionFields, product) {
      this.$gtm.push({
        ecommerce: {
          actionFields,
          products: [product],
        },
      });
    },

    /**
     * Get cancellation protection infofrom a cart item, to be pushed to gtm.
     * @returns object of cancellation protection info.
     */
    checkout_createCancellationProtectionCartItem(parentCartItem) {
      return {
        inventory_id: parentCartItem.inventory_id,
        inventory_item: {
          name: 'cancellation protection',
          position: 0,
        },
        totals: {
          discount: 0,
          total: parentCartItem.cancellation_protection?.totals?.total,
        },
        discount: {
          code: parentCartItem.discount?.code || '',
        },
        index: 0,
        isCancellationProtection: true,
      };
    },
    async logoutBookingPortal() {
      this.$cookies.removeAll({ path: '/', domain: process.env.AUTH0_COOKIE_DOMAIN });
      localStorage.clear();
      sessionStorage.clear();
      this.$nuxt.context.redirect(
        `${process.env.AUTH0_LOGOUT_REDIRECT}/orders?showOrdersList=true`
      );
    },
    /**
     * Construct a gateway data according to the payment method
     * payment_strategy is needed only for Stripe payment method
     */
    checkout_getGatewayData(payload) {
      const paymentProvider = getAppPaymentProvider();
      if (paymentProvider !== PAYMENT_PROVIDERS.STRIPE) {
        return payload;
      }


      const isNewPaymentStrategy = paymentProvider === PAYMENT_PROVIDERS.STRIPE;
      const paymentStrategy = isNewPaymentStrategy ? PAYMENT_METHOD_STRATEGIES.NEW_PAYMENT_ONLY : PAYMENT_METHOD_STRATEGIES.SAVE_CARD_PAYMENT_METHOD;
      return {
        ...payload,
        payment_strategy: paymentStrategy,
      };
    },
  },
};

export default checkout;
