
import { defineComponent } from 'vue';
import BookingItem from '../booking-item/booking-item.vue';
import { ManageBookingState } from '@white-label-types/stores';
import BackButton from '../back-button/back-button.vue';
import IconAlert from '@white-label-icon/icon-alert';
import { NAMED_ROUTES } from '@white-label-configuration/constants';
import { BOOKING_STATUSES } from '@white-label-configuration/constants';
// Store
import { mapState } from 'pinia'
import { useManageBookingStore } from '@white-label-store/manage-booking';

export default defineComponent({
  name: 'BookingProductsCards',
  components: {
    BookingItem,
    BackButton,
    IconAlert,
  },
  computed: {
    ...mapState(useManageBookingStore, ['readBooking']),
    order(): ManageBookingState['manageBookingState'] {
      return this.readBooking;
    },
    orderItems(): ManageBookingState['manageBookingState'][any] {
      return (this.order || {}).items.map((item) => {
        const groupsObj = {};
        for (const [group, value] of Object.entries(
          item.inventory_option?.details?.groups
        )) {
          groupsObj[group] = (value.totals || {}).quantity || 0;
        }

        const itemPiiData = this.order.piiData.find(
          (piiData) => piiData.bookingItemId === item.id
        );

        return {
          privateTerminalBooking: true,
          status: item.status,
          id: this.order.orderId,
          productId: item.id,
          piiDataStatus: itemPiiData?.status,
          ...{
            orderItems: [
              {
                ...item.inventory_item,
                displayName: item.inventory_item?.display_name,
                startedAt: { datetime: item.product?.started_at.datetime },
                closedAt: { datetime: item.product?.closed_at.datetime },
                groups: groupsObj,
                baggageType: item.details?.baggage_type || item.inventory_item_details?.baggage_type,
                mealType: item.details?.meal_type || item.inventory_item_details?.meal_type,
                airlineName: item.details?.airline_name || item.inventory_item_details?.airline_name,
                hasPassedDeadline: item.hasPassedDeadline,
                status: item.status,
              },
            ],
          },
        };
      });
    },
    filteredOrderItems(): OrderItem[] {
      return this.orderItems.length > 0 && this.orderItems.filter(
        (orderItem) => {
          return orderItem?.status.toUpperCase() !== BOOKING_STATUSES.CANCELLED;
        }
      );
    },
  },
  methods: {
    goToBookingHome() {
      this.$router.push(NAMED_ROUTES.home);
    },
  },
});
