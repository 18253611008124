import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type {
  ParkingFormData,
  LoungeFormData,
} from '@white-label-types/search-box';
import type { SearchProducts } from '@white-label-types/parking-booking';
import {
  PARKING_CATEGORY_TYPES,
  PARKING_SORT_OPTIONS,
  PRODUCT_TYPES,
} from '@white-label-configuration/constants';
import { isInExperiment } from '@white-label-plugin/launch-darkly';

type Playbook = { order: number; product_id: number };
type Playbooks = {
  featured: Playbook[];
  boosted: Playbook[];
  bottom: Playbook[];
};
type SearchResultsFormData = ParkingFormData | LoungeFormData | null;
type Timestamp = Date | string | null;
type FilterCategory = typeof PARKING_CATEGORY_TYPES[keyof typeof PARKING_CATEGORY_TYPES];
type InventoryMetadata = { [key: string]: boolean };
type SearchResultsByProductType = { [key in typeof PRODUCT_TYPES[keyof typeof PRODUCT_TYPES]]?: SearchProducts[] };

export const useSearchResultsStore = defineStore('searchResults', () => {
  const searchResults = ref<SearchProducts[]>([]);
  const searchResultsByProductType = ref<SearchResultsByProductType>({
    [PRODUCT_TYPES.PARKING]: [],
    [PRODUCT_TYPES.LOUNGES]: [],
    [PRODUCT_TYPES.FASTTRACK]: [],
  });
  const searchExtrasResults = ref<SearchProducts[]>([]);
  const searchResultsFormData = ref<SearchResultsFormData>({
    entryDate: '',
    entryTime: null,
    exitDate: '',
    exitTime: null,
  });
  const searchResultsTimestamp = ref<Timestamp>(null);
  const searchErrorApi = ref(false);
  const searchToken = ref('')
  const playbooks = ref<Playbooks>({
    featured: [],
    boosted: [],
    bottom: [],
  });
  const yourSearchProductId = ref<number | null>(null);
  const activeFilterCategoryType = ref<FilterCategory>(PARKING_CATEGORY_TYPES.ALL);
  const activeSort = ref('recomended');
  const inventoryMetadata = ref<InventoryMetadata>({});

  const sortedSearchResults = computed(() => {
    const filteredSearchResults =
      activeFilterCategoryType.value === PARKING_CATEGORY_TYPES.ALL
        ? [...searchResults.value]
        : searchResults.value.filter(
            (result) =>
              result.product_option.code === activeFilterCategoryType.value
          );

    if (activeSort.value === PARKING_SORT_OPTIONS.PRICE) {
      return filteredSearchResults.sort((first, second) => {
        const firstPrice =
          first.productOptions[0].totals.channel_based.total_discounted;
        const firstName = first.name;
        const secondPrice =
          second.productOptions[0].totals.channel_based.total_discounted;
        const secondName = second.name;

        if (firstPrice > secondPrice) {
          return 1;
        }
        if (firstPrice < secondPrice) {
          return -1;
        }
        if (firstPrice === secondPrice) {
          if (firstName > secondName) {
            return 1;
          }
          if (firstName < secondName) {
            return -1;
          }
        }
        return 0;
      });
    }

    if (activeSort.value === PARKING_SORT_OPTIONS.DISTANCE) {
      return filteredSearchResults.sort((first, second) => {
        const firstDistance = Number(first.transfer.distance);
        const firstName = first.name;
        const secondDistance = Number(second.transfer.distance);
        const secondName = second.name;

        if (firstDistance > secondDistance) {
          return 1;
        }
        if (firstDistance < secondDistance) {
          return -1;
        }
        if (firstDistance === secondDistance) {
          if (firstName > secondName) {
            return 1;
          }
          if (firstName < secondName) {
            return -1;
          }
        }
        return 0;
      });
    }

    const yourSearchProduct = filteredSearchResults.find(
      (item) => item.id === yourSearchProductId.value
    );

    const featured = filteredSearchResults
      .filter((item) =>
        playbooks.value?.featured.some(
          (product) =>
            product.product_id === item.id &&
            product.product_id !== yourSearchProduct?.id
        )
      )
      .sort((first, second) => {
        const firstOptions = playbooks.value?.featured.find(
          (product) => product.product_id === first.id
        );
        const secondOptions = playbooks.value?.featured.find(
          (product) => product.product_id === second.id
        );

        if (firstOptions && secondOptions) {
          return firstOptions.order > secondOptions.order ? 1 : -1;
        }
        return 0;
      });

    const boosted = filteredSearchResults
      .filter((item) =>
        playbooks.value?.boosted.some(
          (product) =>
            product.product_id === item.id &&
            product.product_id !== yourSearchProduct?.id
        )
      )
      .sort((first, second) => {
        const firstOptions = playbooks.value?.boosted.find(
          (product) => product.product_id === first.id
        );
        const secondOptions = playbooks.value?.boosted.find(
          (product) => product.product_id === second.id
        );

        if (firstOptions && secondOptions) {
          return firstOptions.order > secondOptions.order ? 1 : -1;
        }
        return 0;
      });

    const bottom = filteredSearchResults
      .filter((item) =>
        playbooks.value?.bottom.some(
          (product) =>
            product.product_id === item.id &&
            product.product_id !== yourSearchProduct?.id
        )
      )
      .sort((first, second) => {
        const firstOptions = playbooks.value?.bottom.find(
          (product) => product.product_id === first.id
        );
        const secondOptions = playbooks.value?.bottom.find(
          (product) => product.product_id === second.id
        );

        if (firstOptions && secondOptions) {
          return firstOptions.order > secondOptions.order ? 1 : -1;
        }
        return 0;
      });

    const others = filteredSearchResults
      .filter(
        (item) =>
          !featured.some((product) => product.id === item.id) &&
          !boosted.some((product) => product.id === item.id) &&
          !bottom.some((product) => product.id === item.id) &&
          yourSearchProduct?.id !== item.id
      )
      .sort((first, second) => {
        const firstPrice =
          first.productOptions[0].totals.channel_based.total_discounted;
        const firstName = first.name;
        const secondPrice =
          second.productOptions[0].totals.channel_based.total_discounted;
        const secondName = second.name;

        if (firstPrice > secondPrice) {
          return 1;
        }
        if (firstPrice < secondPrice) {
          return -1;
        }
        if (firstPrice === secondPrice) {
          if (firstName > secondName) {
            return 1;
          }
          if (firstName < secondName) {
            return -1;
          }
        }
        return 0;
      });

    return [
      // If a product is available which matches the product id from query string, show this at the top of results
      ...(yourSearchProduct ? [yourSearchProduct] : []),
      // Otherwise, featured products show at the top of the results. Order 1 = First
      ...featured,
      // Boosted products will be listed after featured, and ordered based on BE
      ...boosted,
      ...others,
      // Bottom products will be listed last, and ordered based on BE
      ...bottom,
    ];
  });
  const hasExtrasSearchResults = computed(() => {
    return (
      (searchResultsByProductType.value[PRODUCT_TYPES.FASTTRACK]?.length ?? 0) > 0 ||
      (searchResultsByProductType.value[PRODUCT_TYPES.LOUNGES]?.length ?? 0) > 0
    );
  });

  function updateSearchResults(
    payload: {
      searchResults: SearchProducts[];
      formData: SearchResultsFormData;
      timestamp: Timestamp;
      yourSearchProductId: number | null;
    }
  ) {
    searchResults.value = payload.searchResults;
    // @ts-expect-error - At the moment the types are too inconsistent to get this work
    searchResultsFormData.value = { ...payload.formData };
    searchResultsTimestamp.value = payload.timestamp;
    yourSearchProductId.value = payload.yourSearchProductId;
  }

  function updateExtrasSearchResults(
    payload: {
      searchResults: SearchProducts[];
      formData: SearchResultsFormData;
      timestamp: Timestamp;
    }
  ) {
    searchExtrasResults.value = payload.searchResults;
    // @ts-expect-error - At the moment the types are too inconsistent to get this work
    searchResultsFormData.value = { ...payload.formData };
    searchResultsTimestamp.value = payload.timestamp;
  }

  function updateSearchResultsByProductType(
    payload: {
      searchResults: SearchProducts[];
      productType: keyof SearchResultsByProductType;
    }
  ) {
    searchResultsByProductType.value[payload.productType] = payload.searchResults;
  }

  function updateYourSearchProductId(payload: number) {
    yourSearchProductId.value = payload;
  }

  function clearSearchResults() {
    searchResults.value = [];
    searchResultsFormData.value = null;
    searchResultsTimestamp.value = null;
    if (!isInExperiment('CRO_826_HIGHLIGHT_PREVIOUSLY_SELECTED_PARKING')) {
      yourSearchProductId.value = null;
    }
  }

  function updateSearchToken(payload: string) {
    searchToken.value = payload;
  }

  function updatePlaybooks(payload: Playbooks) {
    playbooks.value = payload;
  }

  function updateActiveSort(payload: string) {
    activeSort.value = payload;
  }

  function updateSearchErrorApi(payload: boolean) {
    searchErrorApi.value = payload;
  }

  function updateActiveFilterCategoryType(payload: FilterCategory) {
    activeFilterCategoryType.value = payload;
  }

  function setCartId(payload: { id: number; cart_item_id: string; isTravelExtras: boolean }) {
    const results = payload.isTravelExtras ? searchExtrasResults.value : searchResults.value;
    const productIndex = results.findIndex(
      (item) => item.id === payload.id
    );
    if (productIndex >= 0) {
      results[productIndex].cart_item_id = payload.cart_item_id;
    }
  }

  function removeCartItemId(
    payload: { id?: number; cart_item_id: string, isTravelExtras?: boolean  }
  ) {
    if ( payload.isTravelExtras === undefined){
      payload.isTravelExtras = false;
    }
    const results = payload.isTravelExtras ? searchExtrasResults.value : searchResults.value;
    const productIndex = results.findIndex(
      (item) => item?.cart_item_id === payload.cart_item_id
    );
    if (productIndex >= 0) {
      results[productIndex].cart_item_id = '';
    }
  }

  function convertSearchResults(payload: {
    searchResults: SearchProducts[];
    formData: SearchResultsFormData;
    timestamp: Timestamp;
    yourSearchProductId: number | null;
  }) {
    return new Promise<void>((resolve) => {
      const { searchResults } = payload;

      updateSearchResults({
        searchResults: searchResults.length ? searchResults : [],
        formData: payload.formData,
        timestamp: payload.timestamp,
        yourSearchProductId: payload.yourSearchProductId,
      });
      resolve();
    });
  }

  function convertExtrasSearchResults(
    payload: {
      searchResults: SearchProducts[];
      formData: SearchResultsFormData;
      timestamp: Timestamp;
    }
  ) {
    return new Promise<void>((resolve) => {
      const { searchResults } = payload;

      updateExtrasSearchResults({
        searchResults: searchResults.length ? searchResults : [],
        formData: payload.formData,
        timestamp: payload.timestamp,
      });
      resolve();
    });
  }

  function updateInventoryMetadata(payload: InventoryMetadata): void {
    inventoryMetadata.value = payload;
  }

  return {
    searchResults,
    searchResultsByProductType,
    searchExtrasResults,
    searchResultsFormData,
    searchResultsTimestamp,
    searchErrorApi,
    searchToken,
    playbooks,
    yourSearchProductId,
    activeFilterCategoryType,
    activeSort,
    sortedSearchResults,
    hasExtrasSearchResults,
    clearSearchResults,
    updateSearchToken,
    updatePlaybooks,
    updateActiveSort,
    updateSearchErrorApi,
    updateActiveFilterCategoryType,
    updateSearchResultsByProductType,
    updateYourSearchProductId,
    setCartId,
    removeCartItemId,
    convertSearchResults,
    convertExtrasSearchResults,
    inventoryMetadata,
    updateInventoryMetadata,
  };
},
{
  persist: {
    storage: sessionStorage,
    paths: ['yourSearchProductId']
  }
}
);
